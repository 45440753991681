<template>
  <v-container fluid grid-list-lg>
    <template>      
        <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Listado de usuarios' }]" />            
      <v-card>
        <v-toolbar color="grey darken-1" dark card>
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-spacer />
          <v-btn :to="{ name: 'crearUsers' }" color="primary">
           <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
            Agregar Usuario
          </v-btn>
        </v-toolbar>
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-data-table
                v-model="selected"
                :headers="[
                  { text: 'Nombres', value: 'nombre' },
                  { text: 'Email', value: 'email' },
                  { text: 'Rol', value: 'rol.nombre' },
                  { text: 'Acciones' }
                ]"
                :items="users"
                :loading="loadingUsers"
                :rows-per-page-items="[50, 100]"
                show-select
                class="elevation-1"
              >
                <tr slot="items" slot-scope="props">
                  <td class="px-3">
                    {{ props.item.nombre }}
                  </td>
                  <td class="px-3">
                    {{ props.item.email }}
                  </td>
                   <td class="px-3">
                    {{ props.item.rol.nombre }}
                  </td>
                  <td class="px-3">
                    <v-btn
                      class="ma-0"
                      :to="{ name: 'editUsers', params: { id: props.item.id } }"
                      small
                      icon
                      flat
                      color="info"
                    >
                      <v-icon small>
                        edit
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de Usuarios" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },

  data() {
    return {
      searchUsers: "",
      singleSelect: false,
      selected: [],
      bottomNav: "recent"
    };
  },

  computed: {
    ...mapState({
      users: state => state.users.users,
      loadingUsers: state => state.users.loadingUsers
    })
  },

  watch: {},

  created() {
    this.getUsers();
  },

  methods: {
    ...mapActions({
      getUsers: "users/getUsers"
    })
  }
};
</script>
<style scoped>
.list-buttons {
  color: white;
  background: #283848;
  line-height: 1.1;
  text-align: center;
  font-size: 20px;
  padding: 8px 0 0 10px;
  vertical-align: middle;
  width: 230px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 25px;
}
.title-button {
  font-family: inherit;
}
.tab:hover {
  color: white !important;
  background: #44b5ba !important;
}
</style>
